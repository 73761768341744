
#absence-overview {

  & .table-responsive {
    padding: 2px;
    margin: -2px;
  }

  & thead td {
    min-width: 20px;
    max-width: 20px;
    border-left: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    font-size: small;
  }

  & tbody tr td {
    &.weekday-5, &.weekday-6 {
      background-color: var(--bs-light);
    }
  }

  & tbody tr:hover td {
    &.weekday-5, &.weekday-6 {
      background-color: inherit;
    }
  }

  & tbody td {
    min-width: 20px;
    max-width: 20px;
    border-left: 1px solid #dee2e6;
    padding: 0 0 .25rem 0;

    & .absence-status-pending, & .absence-draft {
      background-image: linear-gradient(
        45deg,
        rgba(255,255,255,.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255,255,255,.15) 50%,
        rgba(255,255,255,.15) 75%,
        transparent 75%,
        transparent
      );
    }

    & .absence-type-illness {
      border-top: 2px solid rgba(255, 0, 0, 0.7);
      border-bottom: 2px solid rgba(255, 0, 0, 0.7);

      &.first {
        border-left: 6px solid rgba(255, 0, 0, 0.7);
      }

      &.last {
        border-right: 6px solid rgba(255, 0, 0, 0.7);
      }
    }

    & .chip {
      height: 10px;
      margin: 0 0 3px 0;
      cursor: pointer;
      border-color: white;
      border-style: solid;
      border-width: 1px 0;

      &.first {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 2px;
        border-left-width: 1px;
      }

      &.last {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 2px;
        border-right-width: 1px;
      }
    }

    & .holiday, & .holiday-placeholder {
      height: 0.25rem;
      margin: 0 0 1px 0;
      background-color: transparent;
    }

    & .holiday {
      cursor: pointer;
      background-color: #0d6efd66;
    }

    &.to-many-entries {
      background-color: #dc354533 !important;
    }

    &.no-valid-day-in-month {
      background: repeating-linear-gradient(
        45deg,
        #dee2e699,
        #dee2e699 0.25rem,
        #00000000 0.25rem,
        #00000000 0.45rem
      );
    }
  }

}
