
body {
    background-color: rgb(233, 236, 239);
}

.cursor-pointer, .cursor-pointer * {
    cursor: pointer;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-line-through {
    text-decoration: line-through;
}

.bg-gray {
    background-color: #e9ecef;
}

.dropdown-menu {
   box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

   .dropdown-item {
      svg {
         color: var(--bs-secondary);
      }
   }

   .dropdown-item.active, .dropdown-item:active {
      svg {
         color: var(--bs-white);
      }
   }
}

nav.navbar {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

// Notwendig, damit beim Profil-Button der Pfeil mit dem Text zentriert ist
nav .dropdown-toggle::after {
    vertical-align: inherit;
}

nav .notification-btn {
   display: flex;
   align-items: center;

   &.has-notification svg {
      animation: notification-bell 2s infinite;
   }

   @keyframes notification-bell {
      5% { transform: rotate(10deg)}
      10% { transform: rotate(-10deg)}
      15% { transform: rotate(10deg)}
      20% { transform: rotate(0deg)}

   }
}


.form-label {
    display: flex;
    align-items: center;
}

#page-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#content {
    flex-grow: 1;
}

#footer {
    width: 100%;
    background-color: var(--bs-light);
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

#footer .container {
    background-color: var(--bs-light);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.imprint p {
    text-align: justify;
}

.imprint p, .imprint h1, .imprint h2, .imprint h3, .imprint h4, .imprint h5, .imprint h6 {
    hyphens: auto;
}

.dropdown-toggle.no-caret::after {
    display: none;
}

.rbc-day-bg.schedule-ok {
    border-top: 5px solid rgba(40,167,69,0.25);
}

.rbc-day-bg.schedule-nok {
    border-top: 5px solid rgba(220,53,69,0.25);
}
// Sorgt dafür, dass im Kalender bei einem Event der Inhalt unterhalb der Uhrzeit steht (und nicht daneben abgeschnitten)
.rbc-event {
    display: block !important; // TODO: Warum muss important verwendet werden?
}

.event-pending {
    background-image: linear-gradient(
                    45deg,
                    rgba(255,255,255,.15) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255,255,255,.15) 50%,
                    rgba(255,255,255,.15) 75%,
                    transparent 75%,
                    transparent
    );
}

.white-space-pre-wrap {
   white-space: pre-line;
}

/*************************/
/* region File Uploader */

.file_uploader {
   flex-direction: column;
   min-height: 15rem;
   min-width: initial !important;
   max-width: initial !important;
   width: 100%;
   gap: 0.5rem;
   border-color: var(--bs-secondary) !important;

   & > svg {
      flex-grow: 1;

      & path {
         fill: var(--bs-primary);
      }
   }

   & > div {
      flex-direction: column;
      align-items: center;
      justify-content: start;
   }

   & .file-types {
      max-width: initial !important;
   }
}

/* endregion */
/*************************/

/*************************/
/* region Floating Panel/Button/Tabs */

.floating-panel,
.floating-nav-panel-md, .floating-nav-panel-lg, .floating-nav-panel-always,
.floating-tabs .tab-content,
.floating-table {
   background-color: var(--bs-white);
   margin: 1rem 0 0 0;
   padding: 1rem;
   border-radius: 0.25rem;
   border: 1px solid rgba(0,0,0,.125);
}

.floating-button {
   border: 1px solid rgba(0,0,0,.125);
   margin-top: 1rem;
   border-radius: 0.25rem;
}

.list-group.floating-panel {
   padding: 0;

   & > button, & > div {
      border-left-width: 0;
      border-right-width: 0;
   }

   & > button:first-child, & > div:first-child {
      border-top-width: 0;
   }

   & > button:last-child, & > div:last-child {
      border-bottom-width: 0;
   }
}

.list-group.floating-nav-panel-md,
.list-group.floating-nav-panel-lg,
.list-group.floating-nav-panel-always
{
   padding: 0;
   border: none;
   overflow: hidden;
   flex-direction: row;

   & .list-group-item {
      border-left-width: 0;
      border-top-width: 1px;
   }

   & .list-group-item:first-child {
      border-left-width: 1px;
      border-top-right-radius: 0;
      border-bottom-left-radius: inherit;
   }

   & .list-group-item:last-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: inherit;
   }

}

@media (min-width: 768px) {
   .list-group.floating-nav-panel-md {
      flex-direction: column;

      & .list-group-item {
         border-left-width: 1px;
         border-top-width: 0;
      }

      & .list-group-item:first-child {
         border-top-width: 1px;
         border-top-right-radius: inherit;
         border-bottom-left-radius: 0;
      }

      & .list-group-item:last-child {
         border-bottom-left-radius: inherit;
         border-top-right-radius: 0;
      }
   }
}

@media (min-width: 992px) {
   .list-group.floating-nav-panel-lg {
      flex-direction: column;

      & .list-group-item {
         border-left-width: 1px;
         border-top-width: 0;
      }

      & .list-group-item:first-child {
         border-top-width: 1px;
         border-top-right-radius: inherit;
         border-bottom-left-radius: 0;
      }

      & .list-group-item:last-child {
         border-bottom-left-radius: inherit;
         border-top-right-radius: 0;
      }
   }
}

.floating-tabs {
   .nav-tabs {
      border-bottom: none;

      .nav-item {

         &:first-child > * {
            border-top-left-radius: 0.25rem;
         }
         &:last-child > * {
            border-top-right-radius: 0.25rem;
         }

         & > * {
            border: 1px solid #DDD;
            margin-bottom: 0;
            border-radius: 0;
            color: var(--bs-info);
            background-color: var(--bs-white);

            &:hover, &:focus {
               background-color: #e9ecef;
            }

            &.active {
               background-color: var(--bs-primary);
               color: var(--bs-white);
               border-color: var(--bs-primary);
            }

            &.disabled {
               cursor: not-allowed;
               pointer-events: all !important;
               color: var(--bs-secondary);
               background-color: var(--bs-light);
            }
         }
      }
   }

   .tab-content {
      margin-top: 0;
      border-top-left-radius: 0;
   }
}

.ft-table {
   .ft-header {
      padding: 1rem 0;
      width: 100%;
      background-color: var(--bs-secondary);
      color: var(--bs-white);
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      font-weight: bold;
      margin: 0 !important;
   }

   .ft-body {
      border-left: 1px solid rgb(233, 236, 239);
      border-right: 1px solid rgb(233, 236, 239);
      border-bottom: 1px solid rgb(233, 236, 239);
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;

      .ft-row {
         margin: 0;
         align-items: stretch;
         border-bottom: 1px solid rgb(233, 236, 239);

         &, & * {
            cursor: pointer;
         }

         .ft-td {
            padding: .75rem;
            display: flex;
            align-items: center;
         }

         &:last-child {
            border-bottom: none;
         }

         &:hover, &:focus {
            background-color: var(--bs-light);
         }

         &.selected {
            background-color: #007bff44;

            &:hover, &:focus {
               background-color: #007bff66;
            }
         }
      }
   }

   // Disable some styling when used inside floating-panel
   &.floating-panel {
      padding: 0;

      .ft-body {
         border-left: none;
         border-right: none;
         border-bottom: none;
         overflow: hidden;
      }
   }
}

/* endregion */
/*************************/

/*************************/
/* region CSS Tooltip */

div[role=tooltip] {
    z-index: 1100!important;
}

/* endregion */
/*************************/

/*************************/
/* region Datepicker */

// Für Safari notwendig, damit die Navigations-Buttons für die Monate sichtbar sind
.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous {
    white-space: nowrap;
}

// Entfernt doppelten Rand, wenn DatePicker als DropDownMenu gerendert wird
.dropdown-menu .react-datepicker {
    border: none;
}

.react-datepicker-popper {
   z-index: 3;
}

/* endregion */
/*************************/

/*************************/
/* region Changelog */

.changelog h2 {
    margin-top: 4rem;
}

.changelog h4 {
    margin-top: 1rem;
    margin-left: 1rem;
}

.changelog ul {
    margin-left: 1.5rem;
}

/* endregion */
/*************************/

// react-medium-image-zoom

[data-rmiz-modal-overlay="hidden"] {
   background-color: rgba(32, 32, 32, 0.8);
}
[data-rmiz-modal-overlay="visible"] {
   background-color: rgba(32, 32, 32, 0.8);
}

[data-rmiz-modal-overlay], [data-rmiz-modal-img] {
   transition-duration: 0.6s;
   transition-timing-function: linear;
}

[data-rmiz-btn-open] {
   cursor: zoom-in !important;
}
[data-rmiz-btn-close] {
   cursor: zoom-out !important;
}
