#schedule-new {
  --border-color: #dee2e6;
  display: flex;
  flex-direction: column;

  .week {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-right: none;

    .week-of-year {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--border-color);
      width: 4rem;
      padding: 0.5rem 1rem;
    }

    .week-days {
      display: flex;
      flex-direction: row;
      flex: 1;
      border: 1px solid var(--border-color);
      border-top: none;
      border-right: none;

      .week-day {
        flex: 1;
        border-right: 1px solid var(--border-color);

        .week-day-header {
          border-bottom: 1px solid var(--border-color);
          border-top: 1px solid var(--border-color);
          padding: 0.15rem 0.25rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          &.schedule-ok {
            background-color: rgba(40,167,69,0.25);
          }

          .icon {
            margin-right: 0.25rem;
            cursor: pointer;

            path {
              fill: var(--bs-secondary) !important;
            }

            &.icon-schedule-ok:hover path {
              fill: var(--bs-danger) !important;
            }

            &.icon-schedule-nok:hover path {
              fill: var(--bs-success) !important;
            }
          }

          .today {
            background-color: var(--bs-primary);
            color: var(--bs-white);
            border-radius: .2em;
            padding: 0 0.25rem;
          }

        }

        .week-day-classes {
          padding: 0.25rem;
          min-height: 5rem;
        }

      }
    }
  }
}

.coaching-class-card {
  display: block;
  width: 100%;
  min-height: 4rem;
  border: none;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 6px;
  text-align: left;
  background-color: rgb(239, 239, 239);

  .coaches-list {
    display: inline-flex;
    flex-wrap: wrap;

    .badge {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
