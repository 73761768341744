
.loading-overlay-wrapper {
  position:relative;
}

.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;
  overflow: hidden;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &.border-radius {
    border-radius: 0.25rem;
  }
}

