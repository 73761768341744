.badge-icon {
   position: relative;
   display: flex;
   align-items: center;

   & .badge {
      background: var(--bs-danger);
      position: absolute;
      display: inline-block;
      padding: 0;
      top: -1px;
      right: -1px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: border-color .15s ease-in-out;
   }
}

.btn-primary {
   & .badge {
      border: 2px solid var(--bs-primary);
   }

   &:hover .badge, &:focus .badge {
      border-color: #0b5ed7;
   }

   &:active .badge, &.active .badge {
      border-color: #0a58ca;
   }
}

.btn-outline-secondary {
   &:hover .badge, &:active .badge, &.active .badge {
      border-color: #6c757d !important;
   }
}
