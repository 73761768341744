.line-with-text {
  display: flex;
  align-items: center;

  &:before {
    content: ' ';
    flex: 1;
    border-top: 1px solid var(--bs-secondary);
    margin-right: 1rem;
  }
  &:after {
    content: ' ';
    flex: 1;
    border-top: 1px solid var(--bs-secondary);
    margin-left: 1rem;
  }
}